import PropTypes from 'prop-types';
import React from 'react';
import Obfuscate from 'react-obfuscate';
import { Row, Col } from 'react-simple-flex-grid';
import { useStaticQuery, graphql } from "gatsby";

import newsData from '../data/news.yaml';
import metadata from '../data/metadata.yaml';

import News from './News';
import Navigation from './Navigation';

import LogoEPFL from './SVG/LogoEPFL';
import LogoGILAB from './SVG/LogoGILAB';
import LogoRGL from './SVG/LogoRGL';

const phrases = [];
const interval = 3000;
const typistProps = {}

// for (const [index, value] of newsData.entries()) {
//   phrases.push("[" + value.date + "]  →  " + value.text)
// }
for (const [index, value] of newsData.entries()) {
  phrases.push(value.text)
}

// const newsElements = phrases.map( (data) => {
//   return <Marquee list={[data]} direction='horizontal'/>
// });

const Header = (props) => {
  const { site } = useStaticQuery(query)
  // const data = site.siteMetadata
  const data = metadata
  return(
  <header id="header" style={props.timeout ? { display: 'none' } : {}}>
    {/* <div className="content" style={{'min-width': '100%'}}> 
    <div style={{marginTop: '5px', marginBottom: '5px'}}>
        <Row gutter={5}>
        <Col xs={12} sm={2} md={1} lg={1} xl={1}>
          <p style={{margin: '0px', textAlign: 'center'}}> <a className='button_news' onClick={() => {
              props.onOpenArticle('news')
            }}><i className="fa fa-newspaper-o"></i> News </a> </p>
        </Col>
        <Col xs={12} sm={10} md={11} lg={11} xl={11}>
          <p style={{marginTop: '12px', marginBottom: '5px', 'textAlign': 'left'}}>
            <News data={phrases} />
          </p>
        </Col>
      </Row>
    </div>
    </div> */}

    <div className="content">
      <div className="inner">
        <h1>Miguel Crespo</h1>
        <p style={{'text-align': "justify", 'text-justify': "inter-word", 'textTransform': 'initial', 'fontSize': '0.9rem'}}>
          Hi! 👋 I am a <strong>Doctoral Research Assistant</strong> in the <a className='rgl-icon' href='https://rgl.epfl.ch/'><LogoRGL fill="currentColor"/> REALISTIC GRAPHICS LAB</a> at <a className='epfl-icon' href='https://www.epfl.ch/'><LogoEPFL fill="currentColor"/></a> under 
          the supervision of Prof. <a href='https://rgl.epfl.ch/people/wjakob'>WENZEL JAKOB</a>.

          Prior to joining EPFL, I studied a <strong>Bachelor's degree in Computer Science</strong> and a <strong>Master's degree 
          in Biomedical Engineering</strong>, both at <a href='https://www.unizar.es/'>UNIVERSIDAD DE ZARAGOZA</a>. 
          During that time and after, I have been a junior researcher in the <a className='gilab-icon' href="https://graphics.unizar.es"><LogoGILAB fill="currentColor"/> GRAPHICS & IMAGING 
          LAB</a> under the supervision of Prof. <a href="http://adolfo-munoz.com">ADOLFO MUÑOZ</a> and Prof. <a href="http://giga.cps.unizar.es/~ajarabo/">ADRIAN JARABO</a>.
        </p>
        <p style={{'text-align': "justify", 'text-justify': "inter-word", 'textTransform': 'initial', 'fontSize': '0.9rem'}}>
          My work revolves around the different aspects of Physically-Based and Differentiable Rendering, including light transport and reconstruction, 
          with mention on both Transient and Computational Imaging. Nevertheless, I’m always looking forward to learning something new!
        </p>
          
        {/* <div className='line-heading-container'>
          <span className='line-heading'>Contact me</span>
        </div> */}

        <ul className="icons">
            {data.cv_url !== '' && <li>
              <div class="tooltip">
              <a
                href={data.cv_url}
                className="ai ai-cv"
              >
                <span className="label"></span>
              </a>
              <span class="tooltiptext">Curriculum Vitae</span>
              </div>
            </li>}
            {data.email_address !== '' && <li>
              <div class="tooltip">
              <Obfuscate email={data.email_address} aria-label="Email Me">
                <span className="fa fa-envelope"></span>
              </Obfuscate>
              <span class="tooltiptext"> Email me </span>
              </div>
            </li>}
            {data.scholar !== '' && <li>
              <div class="tooltip">
              <a
                href={data.scholar}
                className="icon"
              >
                <i style={{fontSize: 'larger'}} class="ai ai-google-scholar ia-xl"></i>
                <span className="label"></span>
              </a>
              <span class="tooltiptext">Scholar</span>
              </div>
            </li>}
            {data.twitterUsername !== '' && <li>
              <div class="tooltip">
              <a
                href={'https://twitter.com/'+data.twitterUsername}
                className="fab fa-twitter"
              >
                <span className="label"></span>
              </a>
              <span class="tooltiptext">Twitter</span>
              </div>
            </li>}
            {data.linkedin !== '' && <li>
              <div class="tooltip">
              <a href={"https://www.linkedin.com/in/"+data.linkedin} className="fab fa-linkedin">
                <span className="label"></span>
              </a>
              <span class="tooltiptext">Linkedin</span>
              </div>
            </li>}
            {data.github !== '' && <li>
              <div class="tooltip">
              <a
                href={"https://github.com/"+data.github}
                className="fab fa-github"
              >
                <span className="label"></span>
              </a>
              <span class="tooltiptext">GitHub</span>
              </div>
            </li>}
            {data.mastodon !== '' && <li>
              <div class="tooltip">
              <a
                href={data.mastodon}
                className="fab fa-mastodon"
              >
                <span className="label"></span>
              </a>
              <span class="tooltiptext">Mastodon</span>
              </div>
            </li>}
          </ul>
      </div>
    </div>
    
    <Navigation callback={props.onOpenArticle} />

  </header>
)};

Header.propTypes = {
  onOpenArticle: PropTypes.func,
  timeout: PropTypes.bool,
}

export const query = graphql`
  query HeaderData {
    site {
      siteMetadata {
        cv_url
        twitterUsername
        email_address
        linkedin
        github
        scholar
        mastodon
      }
    }
  }
`

export default Header
