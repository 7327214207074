import PropTypes from 'prop-types'
import React from 'react'
import { StaticQuery, graphql, useStaticQuery } from "gatsby"

import PublicationList from '../components/PublicationList'
import ScenesList from '../components/ScenesList'
import BlogList from '../components/BlogList'
import ProjectsList from '../components/ProjectsList'
import LinkList from '../components/LinkList'
import Gallery, { query } from '../components/Gallery'
import NewsList from '../components/NewsList' 
import AboutSection from './AboutSection'

// const ReactMarkdown = require('react-markdown/with-html')
import Obfuscate from 'react-obfuscate';

import { Row, Col } from 'react-simple-flex-grid';
import "react-simple-flex-grid/lib/main.css";

import metadata from '../data/metadata.yaml';

import "../assets/css/custom.css";

class Main extends React.Component {
  render() {
    let close = (
      <div
        className="close"
        onClick={() => {
          this.props.onCloseArticle()
        }}
      >Close</div>
    )

    return (
      <div
        ref={this.props.setWrapperRef}
        id="main"
        style={this.props.timeout ? { display: 'flex' } : { display: 'none' }}
      >
        <article
          id="publications"
          className={`${this.props.article === 'publications' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          {close}
          <h2 className="major">Publications</h2>
          
          <PublicationList/>
        </article>

        <article
          id="resources"
          className={`${this.props.article === 'resources' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none', 'width': '90vw' }}
        >
          {close}
          <h2 className="major">Resources</h2>
          
          <div className='leaders'>
            <div>
            <span className='h3 title'>Useful links</span>
            </div>
          </div>
          <LinkList />
        </article>

        <article
          id="about"
          className={`${this.props.article === 'about' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          {close}
          <h2 className="major">About me</h2>
          {/* <span className="image main">
            <img src={pic03} alt="" />
          </span> */}

          <AboutSection/>
        </article>

        <article
          id="contact"
          className={`${this.props.article === 'contact' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          {close}
          <h2 className="major">Contact</h2>

          <Row gutter={40} justify="center">
            <Col xs={{span: 12, order: 1}} sm={{span: 6, order: 1}}>
              <ul class="fa-ul">
                <li><span class="fa-li"><i class="fa fa-location-arrow"></i></span><div> 
                  <p style={{margin: '0px'}}> 
                  EPFL IC IINFCOM RGL <br></br>
                  <a href="https://plan.epfl.ch/?reset_session=&room=BC_343&dim_floor=3&lang=en&dim_lang=en&baselayer_ref=grp_backgrounds&map_x=2532716&map_y=1152262&map_zoom=14&tree_groups=centres_nevralgiques%2Cacces%2Cmobilite_reduite%2Censeignement%2Ccommerces_et_services%2Cvehicules%2Cinfrastructure_plan_grp&tree_group_layers_centres_nevralgiques=information_epfl%2Cguichet_etudiants&tree_group_layers_acces=metro&tree_group_layers_mobilite_reduite=&tree_group_layers_enseignement=&tree_group_layers_commerces_et_services=&tree_group_layers_vehicules=&tree_group_layers_infrastructure_plan_grp=batiments_query_plan">BC 343 (Bâtiment BC) </a> <br></br>
                  Station 14 <br></br>
                  Lausanne 1015, Switzerland
                  </p>
                </div>
                </li>
              </ul>
            </Col>
            <Col xs={{span: 12, order: 2}} sm={{span: 6, order: 2}}>
              <ul class="fa-ul">
                <li><span class="fa-li"><i class="fa fa-phone"></i></span><div> 
                  <p style={{margin: '0px', marginBottom: '1rem'}}> 
                    <Obfuscate tel={metadata.telephone} />
                  </p>
                </div>
                </li>
                <li><span class="fa-li"><i class="fa fa-envelope"></i></span><div> 
                  <p style={{margin: '0px'}}> 
                    <Obfuscate email={metadata.email_address} />
                  </p>
                </div>
                </li>
              </ul>
            </Col>
          </Row>
        </article>

        {/* <article
          id="blog"
          className={`${this.props.article === 'blog' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none', width: '70vw' }}
        >
          {close}
          <h2 className="major">Blog</h2>
          
          <BlogList/>
        </article> */}

        <article
          id="software"
          className={`${this.props.article === 'software' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none', width: '70vw' }}
        >
          {close}
          <h2 className="major">Software</h2>
          
          {/* <br/>
          
          <div className='leaders'>
            <div>
            <span className='h3 title'>Projects</span>
            </div>
          </div> */}
          <ProjectsList/>
        </article>

        <article
          id="news"
          className={`${this.props.article === 'news' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          {close}
          <h2 className="major">News</h2>
          
          <NewsList/>
        </article>

        {/* <article
          id="gallery"
          className={`${this.props.article === 'gallery' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none', 'width': '90vw' }}
        >
          {close}
          <h2 className="major">Gallery</h2>

          <StaticQuery
            query={queryGallery}
            render={data => (
              <Gallery
                itemsPerRow={[2, 4]}
                images={data.allFile.edges.map(({ node }) => ({
                  ...node.childImageSharp.fluid,
                  publicURL: node.publicURL
                  // caption: `${node.childImageSharp.fluid.originalName}`,
                }))}
              />
            )}
          />
        </article> */}

      </div>
    )
  }
}

// const queryGallery = graphql`
// query GalleryList {
//   allFile(filter: {
//       extension: {regex: "/(jpg)|(jpeg)|(png)/"},
//       sourceInstanceName: {eq: "gallery"}
//     }, sort: {fields: childImageSharp___fluid___originalName, order: ASC})
//   {
//     edges {
//       node {
//       childImageSharp {
//           fluid(maxWidth: 400) {
//             originalName
//             aspectRatio
//             ...GatsbyImageSharpFluid_withWebp
//           }
//         }
//       }
//     }
//   }
// }`;

const queryGallery = graphql`
query GalleryList {
  allFile(filter: {
      extension: {regex: "/(jpg)|(jpeg)|(png)/"},
      sourceInstanceName: {eq: "gallery"}
    }, sort: {fields: childImageSharp___fluid___originalName, order: ASC})
  {
    edges {
      node {
        childImageSharp {
          fluid(maxWidth: 1000) {
            base64
            tracedSVG
            aspectRatio
            src
            srcSet
            srcWebp
            srcSetWebp
            sizes
            originalImg
            originalName
          }
        },
        publicURL
      },
    }
  }
}`;

Main.propTypes = {
  route: PropTypes.object,
  article: PropTypes.string,
  articleTimeout: PropTypes.bool,
  onCloseArticle: PropTypes.func,
  timeout: PropTypes.bool,
  setWrapperRef: PropTypes.func.isRequired,
}

export default Main
