import React from "react"

import { Row, Col } from 'react-simple-flex-grid';

import portrait from '../images/portrait.jpg'
import aboutData from '../data/about.yaml'
import LogoEPFL from './SVG/LogoEPFL';

const parseSupervisor = data => {
    const len = data.length
    var res = (len === 1) ? 'Advisor: ' : 'Advisors: '
    for (let i = 0; i < len; i++) {
        res += data[i]
        // res += (i !== len-1) ? ' & ' : ', '
        if (i === len-2) {
            res += ' & '
        } else if (i < len-1) {
            res += ', '
        }
    }
    return res
}

const generateEducation = data => {
    const lenEducation = aboutData['education'].length

    return <div id='education' className="about-section">
    <div className='leaders'>
        <div>
        <span className='h3 title'>Education</span>
        <span className='label'><i class="fa fa-graduation-cap"/></span>
        </div>
    </div>

    <ul class="fa-ul noLeftMarginMobile">
        {
            aboutData['education'].map((data, index) => {
                // return <li><span class="fa-li"><i class="fa fa-graduation-cap"></i></span>
                return <li>
                <div style={{marginBottom: '1rem'}}>
                    <div className='p-margin-about'>
                        <span>
                            <strong>
                            {data.name !== '' && <span> {data.name} </span>}
                            {data.lab !== '' && <span> @ <a href={data.lab_url}> {data.lab} </a> </span>}
                            </strong>
                        </span>
                        <div className='badge-container minus2MarginTop' style={{fontSize: '0.85rem'}}>
                            <span className="badge-extra"> <i class="fa fa-calendar" style={{marginRight: '0.25rem'}}/>  {data.duration} </span>
                        </div>
                    </div>

                    <div className='p-margin-about'>
                        <span style={{'fontVariant': 'small-caps'}}> 
                            {data.university}
                        </span>
                        <div className='badge-container minus2MarginTop' style={{fontSize: '0.85rem'}}>
                            <span className="badge-extra"> <i class="fa fa-map-marker" style={{marginRight: '0.25rem'}}/> {data.location} </span>
                        </div>
                    </div>

                    {data.advisor !== '' && 
                    <p className='small-info'>
                        <i class="fa fa-user-tie" style={{marginRight: '0.25rem'}}></i> Advisor: {data.advisor}
                    </p>
                    }

                    {data.thesis !== '' && 
                    <p className='small-info'>
                        <i class="fa fa-book" style={{marginRight: '0.25rem'}}></i> Thesis: {data.thesis} <span style={{marginLeft: '2rem'}}> {data.thesis_grade} </span>
                    </p>
                    }
                </div>
                { index < lenEducation-1 && <hr class="dotted"/>}
                </li>
            })
        }
    </ul>
    </div>
}

const generateExperience = data => {
    return <div id='experience' className="about-section">
        <div className='leaders'>
            <div>
            <span className='h3 title'>Experience</span>
            <span className='label'><i class="fa fa-briefcase"/></span>
            </div>
        </div>

        <ul class="fa-ul noLeftMarginMobile">
            {
                aboutData['experience'].map((data, index) => {
                    // return <li><span class="fa-li"><i class="fa fa-briefcase"></i></span>
                    return <li>
                    <div style={{marginBottom: '1rem'}}>
                        <div className='p-margin-about'>
                            <span>
                                <strong>
                                {data.workplace_url !== '' && <a href={data.workplace_url}><span> {data.workplace} </span></a>}
                                {data.workplace_url === '' && <span> {data.workplace} </span>}
                                </strong>
                            </span>
                            <div className='badge-container minus2MarginTop' style={{fontSize: '0.85rem'}}>
                                <span className="badge-extra"> <i class="fa fa-calendar" style={{marginRight: '0.25rem'}}/>  {data.duration} </span>
                            </div>
                        </div>
                        <div className='p-margin-about'> 
                            <span style={{'fontVariant': 'small-caps'}}> 
                                {data.position}
                            </span>
                            <div className='badge-container minus2MarginTop' style={{fontSize: '0.85rem'}}>
                                <span className="badge-extra"> <i class="fa fa-map-marker" style={{marginRight: '0.25rem'}}/> {data.location} </span>
                            </div>
                        </div>

                        {data.thesis !== '' && <p style={{'color': 'rgba(255,255,255,.6)', 'font-size': '.85rem', 'margin': '0px'}}>
                            <i class="fa fa-building" style={{marginRight: '0.25rem'}}></i> {data.department} <span style={{marginLeft: '2rem'}}> {data.thesis_grade} </span>
                        </p>
                        }  

                        {data.advisor !== '' && <p style={{'color': 'rgba(255,255,255,.6)', 'font-size': '.85rem', 'margin': '0px'}}>
                            <i class="fa fa-user-tie" style={{marginRight: '0.25rem'}}></i> {parseSupervisor(data.supervisor)}
                        </p>
                        }
                    </div>
                    </li>
                })
            }
        </ul>
    </div>
}

const generateTalks = data => {
    return <div id='talks' className="about-section">
    <div className='leaders'>
        <div>
        <span className='h3 title'>Talks</span>
        <span className='label'><i class="fa fa-quote-right"/></span>
        </div>
    </div>

    <ul class="fa-ul noLeftMarginMobile">
        {
            aboutData['talks'].map((data, index) => {
                // return <li><span class="fa-li"><i class="fa fa-quote-right"></i></span>
                return <li>
                <div style={{marginBottom: '1rem'}}>
                    <div style={{marginBottom: '0rem'}}>
                        <span>
                            <strong>
                            {data.name !== '' && <span> {data.name} </span>}
                            </strong>
                        </span>
                    </div>
                    <div className='p-margin-about'> 
                        <span style={{'fontVariant': 'small-caps'}}> 
                            {data.type}
                        </span>
                        <div className='badge-container minus2MarginTop' style={{fontSize: '0.85rem'}}>
                            <span className="badge-extra"> <i class="fa fa-calendar" style={{marginRight: '0.25rem'}}/> {data.date} </span>
                        </div>
                    </div>

                    {data.location !== '' && <p style={{'color': 'rgba(255,255,255,.6)', 'font-size': '.85rem', 'margin': '0px'}}>
                        <i class="fa fa-map-marker" style={{marginRight: '0.25rem'}}></i> {data.location}
                    </p>
                    }
                </div>
                </li>
            })
        }
    </ul>
    </div>
}

const generateAwardsGrants = data => {
    return <div id='awards' className="about-section">
    <div className='leaders'>
        <div>
        <span className='h3 title'>Awards & Grants</span>
        <span className='label'><i class="fa fa-award"/></span>
        </div>
    </div>

    <ul class="fa-ul noLeftMarginMobile">
        {
            aboutData['awards_grants'].map((data, index) => {
                // return <li><span class="fa-li"><i class="fa fa-quote-right"></i></span>
                return <li>
                <div style={{marginBottom: '1rem'}}>
                    <div style={{marginBottom: '0rem'}}>
                        <span>
                            <strong>
                            {data.name !== '' && <span> {data.name} </span>}
                            </strong>
                        </span>
                    </div>
                    <div className='p-margin-about'> 
                        <span style={{'fontVariant': 'small-caps'}}> 
                            {data.giver}
                        </span>
                        <div className='badge-container minus2MarginTop' style={{fontSize: '0.85rem'}}>
                            <span className="badge-extra"> <i class="fa fa-calendar" style={{marginRight: '0.25rem'}}/> {data.date} </span>
                        </div>
                    </div>

                    {data.location !== '' && <p style={{'color': 'rgba(255,255,255,.6)', 'font-size': '.85rem', 'margin': '0px'}}>
                        {data.msg}
                    </p>
                    }
                </div>
                </li>
            })
        }
    </ul>
    </div>
}

const generateCompetitions = data => {
    return <div id='competitions' className="about-section">
    <div className='leaders'>
        <div>
        <span className='h3 title'>Competitions</span>
        <span className='label'><i class="fa fa-trophy"/></span>
        </div>
    </div>

    <ul class="fa-ul noLeftMarginMobile">
        {
            aboutData['competitions'].map((data, index) => {
                // return <li><span class="fa-li"><i class="fa fa-quote-right"></i></span>
                return <li>
                <div style={{marginBottom: '1rem'}}>
                    <div style={{marginBottom: '0rem'}}>
                        <span>
                            <strong>
                            {data.name !== '' && <span> {data.name} </span>}
                            </strong>
                        </span>
                    </div>
                    <div className='p-margin-about'> 
                        <span style={{'fontVariant': 'small-caps'}}> 
                            {data.type}
                        </span>
                        <div className='badge-container minus2MarginTop' style={{fontSize: '0.85rem'}}>
                            <span className="badge-extra"> <i class="fa fa-calendar" style={{marginRight: '0.25rem'}}/> {data.date} </span>
                        </div>
                    </div>

                    {data.msg !== '' && <p style={{'color': 'rgba(255,255,255,.6)', 'font-size': '.85rem', 'margin': '0px'}}>
                        {data.msg}
                    </p>
                    }
                </div>
                </li>
            })
        }
    </ul>
    </div>
}

const generateTeaching = data => {
    return <div id='teaching' className="about-section">
    <div className='leaders'>
        <div>
        <span className='h3 title'>Teaching</span>
        <span className='label'><i class="fa fa-chalkboard-user"/></span>
        </div>
    </div>

    <ul class="fa-ul noLeftMarginMobile">
        {
            aboutData['teaching'].map((data, index) => {
                // return <li><span class="fa-li"><i class="fa fa-quote-right"></i></span>
                return <li>
                <div style={{marginBottom: '1rem'}}>
                    <div style={{marginBottom: '0.2rem'}}>
                        <span>
                            <strong>
                            <span> {data.course} </span>
                            </strong>
                            {data.location === 'EPFL' && <span className="epfl-icon" style={{'color': 'rgba(255,255,255,.6)', 'font-size': '.85rem', 'margin': '0px', 'marginLeft': '1rem'}}>
                                <i class="fa fa-map-marker" style={{marginRight: '0.25rem'}}></i> {<LogoEPFL fill="currentColor"/>}
                            </span>
                            }
                        </span>
                    </div>
                    <div className='p-margin-about'>
                        { 
                            data['semesters'].map(( data2, index ) => {
                                return <div className='leaders2'>
                                    <div>
                                        <div className="title"> 
                                            <a href={data2.url}>
                                            <span style={{'fontVariant': 'small-caps'}}> 
                                            <i class="fa fa-user-tie" style={{'color': 'rgba(255, 255, 255, 0.6)'}}/> {data2.position} 
                                            </span>
                                            </a>
                                        </div>
                                        <div className='label' style={{fontSize: '0.85rem'}}>
                                            <span className=""> <i class="fa fa-calendar" style={{marginRight: '0.25rem', fontSize: '0.85rem', 'color': 'rgba(255, 255, 255, 0.6)'}}/> {data2.date} </span>
                                        </div>
                                    </div>
                                </div>
                            })
                        }
                    </div>
                </div>
                </li>
            })
        }
    </ul>
    </div>
}

const generateStudents = data => {
    return <div id='students' className="about-section">
    <div className='leaders'>
        <div>
        <span className='h3 title'>Supervised Students</span>
        <span className='label'><i class="fa fa-users"/></span>
        </div>
    </div>

    <ul class="fa-ul noLeftMarginMobile">
        {
            aboutData['students'].map((data, index) => {
                // return <li><span class="fa-li"><i class="fa fa-quote-right"></i></span>
                return <li>
                <div style={{marginBottom: '1rem'}}>
                    <div style={{marginBottom: '0rem'}}>
                        <span>
                            <strong>
                            <span> {data.project} </span>
                            </strong>
                        </span>
                    </div>
                    <div className='p-margin-about'>
                        <span style={{'fontVariant': 'small-caps'}}> 
                            {data.name} 
                        </span>
                       
                        <div className='badge-container minus2MarginTop' style={{fontSize: '0.85rem'}}>
                            <span className="badge-extra"> <i class="fa fa-calendar" style={{marginRight: '0.25rem'}}/> {data.date} </span>
                        </div>

                        {data.msg !== '' && <p style={{'color': 'rgba(255,255,255,.6)', 'font-size': '.85rem', 'margin': '0px'}}>
                            {data.type}
                            {data.location === 'EPFL' && <span className="epfl-icon" style={{'color': 'rgba(255,255,255,.6)', 'font-size': '.85rem', 'margin': '0px', 'marginLeft': '1rem'}}>
                                <i class="fa fa-map-marker" style={{marginRight: '0.25rem'}}></i> {<LogoEPFL fill="currentColor"/>}
                            </span>
                            }
                        </p>
                        }
                    </div>
                </div>
                </li>
            })
        }
    </ul>
    </div>
}

const generateProfessionalService = data => {
    return <div id='professional_service' className="about-section">
    <div className='leaders'>
        <div>
        <span className='h3 title'>Professional Service</span>
        <span className='label'><i class="fa fa-user-secret"/></span>
        </div>
    </div>

    <ul class="fa-ul noLeftMarginMobile">
        {
            aboutData['professional_service'].map((data, index) => {
                // return <li><span class="fa-li"><i class="fa fa-quote-right"></i></span>
                return <li>
                <div style={{marginBottom: '1rem'}}>
                    <div style={{marginBottom: '0.2rem'}}>
                        <span>
                            <strong>
                                <span> {data.name} </span>
                            </strong>
                        </span>
                    </div>
                    <div className='p-margin-about'>
                        { 
                            data['tasks'].map(( data2, index ) => {
                                return <div className='leaders2'>
                                    <div>
                                        <div className="title"> 
                                            <span style={{'fontVariant': 'small-caps'}}> 
                                            <i class="fa fa-caret-right" style={{'color': 'rgba(255, 255, 255, 0.6)'}}/> {data2.name} 
                                            </span>
                                        </div>
                                        <div className='label' style={{fontSize: '0.85rem'}}>
                                            <span className=""> <i class="fa fa-calendar" style={{marginRight: '0.25rem', fontSize: '0.85rem', 'color': 'rgba(255, 255, 255, 0.6)'}}/> 
                                            {data2.years.join(', ')} 
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            })
                        }
                    </div>
                </div>
                </li>
            })
        }
    </ul>
    </div>
}

const AboutSection = props => (
    <div>
    <Row gutter={40} justify="center">
        <Col xs={{span: 12, order: 2}} lg={{span: 8, order: 1}}>

            {generateEducation(aboutData)}

        </Col>
        <Col xs={{span: 6, order: 1}} lg={{span: 4, order: 2}}>
            <span className="image main" style={{'margin': '0px', paddingBottom: '10px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}> 
            <img src={portrait} alt="" style={{'border-radius': '25%', 'maxWidth': '15rem'}} />
            </span>
        </Col>
    </Row>

    <Row gutter={40} justify="center">
        <Col xs={{span: 12, order: 1}} lg={{span: 6, order: 1}}>

            {generateExperience(aboutData)}
            {generateTeaching(aboutData)}
            {generateAwardsGrants(aboutData)}
            {generateStudents(aboutData)}
            
        </Col>
        <Col xs={{span: 12, order: 2}} lg={{span: 6, order: 2}}>
            {generateTalks(aboutData)}
            {generateProfessionalService(aboutData)}
            {generateCompetitions(aboutData)}
        </Col>
    </Row>
    </div>
);

export default AboutSection;
